import { createStore } from 'vuex'

export default createStore({
  state: {
    // BaseApiURL:"https://moe-ceremony.manialab.sa/api/",
    BaseApiURL:"https://moe-ceremony.com/api/",
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
