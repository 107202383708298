import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from "vue-axios";
import axios from "axios";
import VueToast from 'vue-toast-notification';
import VueCookies from "vue-cookies";

import 'vue-toast-notification/dist/theme-sugar.css';
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";

const app = createApp(App).use(store).use(router);
app.use(VueAxios, axios);
app.use(VueToast);
app.use(VueCookies);

app.mount("#app");
